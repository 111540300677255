import React, { useEffect, useState } from 'react';
import {
    HStack,
    PresenceTransition,
    Text,
    useColorModeValue,
    VStack,
    Box,
} from 'native-base';
import { FaArrowDown } from 'react-icons/fa';

const ScrollToSeeMore = () => {
    const [ showNudge, setShowNudge ] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY < 400) {
                setShowNudge(true);
            } else {
                setShowNudge(false);
            }
        });
    }, []);

    const textColor = useColorModeValue('#B8B8B8', '#DADADA');

    const arrow = (
        <FaArrowDown
            color={textColor}
            size={28}
        />
    );

    return (
        <PresenceTransition visible={showNudge} initial={{
            opacity: 0
        }} animate={{
            opacity: 1,
            transition: {
                duration: 250
            }
        }}>
            <Box
                mt={{base: '10px', lg: '0px'}}
            >
                <div className='scrollDownContainer'>
                    <VStack
                        w='100%'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Text
                            fontSize='18'
                            fontWeight={500}
                            letterSpacing={5}
                            pb='5px'
                            color={textColor}
                        >
                            {'Scroll for More'}
                        </Text>
                        <HStack
                            space={3}
                        >
                            {arrow}
                            {arrow}
                            {arrow}
                        </HStack>
                    </VStack>
                </div>
            </Box>
        </PresenceTransition>
    );
};

export {
    ScrollToSeeMore,
};
