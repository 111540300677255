import React from 'react';
import {
    HStack,
    VStack,
    Button,
    Text,
    useColorMode,
    useBreakpointValue,
} from 'native-base';
import { Link, useNavigate } from 'react-router-dom';
import {
    handleScrollNavClick,
    ToggleDarkMode
} from '../NavBar';
import {
    FaEnvelope,
    FaLinkedin,
    FaArrowLeft
} from 'react-icons/fa';

interface NavBackProps {
    pageRef: React.MutableRefObject<HTMLElement | null>;
}

// initializing colors again here from theme; these match the text colors, but since
// react icons don't allow use of the NativeBase theme, this is a work around
const iconColorLight = '#0f0f0f';
const iconColorDark = '#FFFFFB';

const NavBackHeader = (props: NavBackProps) => {
    const navigate = useNavigate();

    const { colorMode } = useColorMode();
    const iconColor = colorMode === 'light' ? iconColorLight : iconColorDark;

    const isLg = useBreakpointValue({
        base: false,
        lg: true,
    });

    const barIconSize = useBreakpointValue({
        base: '24px',
        lg: '30px',
    });


    const navBack = (
        <Button
            onPress={()=>{navigate(-1);}}
            _light={{
                bg: 'none',
                _pressed: { bg: 'none' },
                _hover: { bg: 'none' },
                color: 'text.dark',
            }}
            _dark={{
                bg: 'none',
                _pressed: { bg: 'none' },
                _hover: { bg: 'none' },
                color: 'text.light',
            }}
        >
            <HStack
                alignItems='center'
                space={3}
            >
                <FaArrowLeft
                    size={barIconSize}
                    color={iconColor}
                    className='gelatine'
                />
                <Text
                    fontSize={'18px'}
                    fontWeight = '600'
                >
                    {isLg? ('Home') : ('Home')}
                </Text>
            </HStack>
        </Button>
    );

    return (
        <HStack
            position='fixed'
            bg={colorMode === 'light' ? 'bg.light' : 'bg.dark'}
            space={3}
            alignItems='center'
            justifyContent='center'
            w='100%'
            h={{ base: '50px', lg: '110px' }}
            zIndex='100'
            opacity={'90%'}
        >
            <HStack
                justifyContent='left'
                alignItems='center'
                w='40vw'
                pl={{ base: '30px', lg: '40px' }}
            >
                {navBack}
            </HStack>
            <HStack
                justifyContent='center'
                alignItems='center'
                w='20vw'
                h='100%'
            >
                <VStack
                    // pt='10px'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Button
                        onPress={() => handleScrollNavClick(props.pageRef)}
                        _light={{
                            bg: 'none',
                            _pressed: { bg: 'none' },
                            _hover: { bg: 'none' },
                            color: 'text.dark',
                        }}
                        _dark={{
                            bg: 'none',
                            _pressed: { bg: 'none' },
                            _hover: { bg: 'none' },
                            color: 'text.light',
                        }}
                    >
                        <Text
                            textAlign={'center'}
                            fontWeight='bold'
                            fontSize={{ base: '25px', lg: '30px' }}
                            lineHeight={{ base: '0px', lg: '25px' }}
                        >
                            <div className={isLg ? 'wave' : ''}>
                                {isLg ? ('O B\nM') : ('O B M')}
                            </div >
                        </Text>
                    </Button>
                </VStack>
            </HStack>
            <HStack
                justifyContent='right'
                alignItems='center'
                w='40vw'
                h='100%'
                pr={{ base: '30px', lg: '40px' }}
                space={5}
            >
                <Link
                    to='#'
                    onClick={(e) => {
                        window.location.href = 'mailto:olivermillard@gmail.com';
                        e.preventDefault();
                    }}
                >
                    <FaEnvelope
                        size={barIconSize}
                        color={iconColor}
                        className='gelatine'
                    />
                </Link>
                <Link
                    to='#'
                    onClick={(e) => {
                        window.open('https://www.linkedin.com/in/oliver-millard-44a06283/');
                        e.preventDefault();
                    }}
                >
                    <FaLinkedin
                        size={barIconSize}
                        color={iconColor}
                        className='gelatine'
                    />
                </Link>
                {isLg ?
                    <ToggleDarkMode /> : <></>
                }
            </HStack>
        </HStack>
    );
};

export {
    NavBackHeader
};
