import { Box, useColorModeValue } from 'native-base';
import React from 'react';


const FullScreenLine = () => {
    return(
        <Box
            w='100%'
            h='4px'
            my='4px'
            borderRadius='10px'
            bg={useColorModeValue('text.light', 'text.dark')}
        >
        </Box>
    );
};

export {
    FullScreenLine
};
