import { Routes, Route, useLocation } from 'react-router-dom';

import {
    Home,
    Resume,
    CV
} from './pages';
import React from 'react';
import {
    NarrativeProj,
    VolleysProj,
    MobileProj
} from './pages/ProjPages';

const Main = () => {
    const location = useLocation();

    return (
        <>
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<Home/>} />
                <Route path='/cv' element={<CV />} />
                <Route path='/resume' element={<Resume />} />
                <Route path='/projects/narrative' element={<NarrativeProj />} />
                <Route path='/projects/conversationalFlow' element={<VolleysProj />} />
                <Route path='/projects/mobileConversion' element={<MobileProj />} />
            </Routes>
        </>
    );
};

export default Main;
