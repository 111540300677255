import { NavBackHeader } from '../../components/common/NavBackHeader';
import {
    Box,
    useColorModeValue,
    VStack,
    Text,
    PresenceTransition,
    useBreakpointValue,
} from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { FullScreenLine } from '../../components/common/FullscreenLine';

const VolleysProj = () => {
    // scroll to top of page after routing
    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    useEffect(() => onTop(), [ routePath ]);

    const [ pageLoaded, setPageLoaded ] = useState(false);
    useEffect(() => setPageLoaded(true), [ pageLoaded ]);

    const pageRef = useRef<null | HTMLElement>(null);

    const imgW = useBreakpointValue({
        base: '60%',
        sm: '75%',
        md: '50%',
        lg: '50%',
    });

    const sectionHeaderText = (text: string) => {
        return (
            <Text
                fontSize={{base: '24px', lg: '32px'}}
                fontWeight={600}
            >
                {text}
            </Text>
        );
    };

    const bodyText = (text: string) => {
        return (
            <Text
                fontSize={{base: '16px', lg: '20px'}}
                fontWeight={500}
                w='100%'
            >
                {text}
            </Text>
        );
    };

    return(
        <Box
            height='100%'
            minHeight='100vh'
            bg={useColorModeValue('bg.light', 'bg.dark')}
        >
            <Box
                position={'absolute'}
                top={0}
                h={0}
                ref={pageRef}
            />
            <NavBackHeader
                pageRef = {pageRef}
            />
            <VStack
                w='100%'
                h='100%'
                mt={{ base: '50px', lg: '100px' }}
                bg={useColorModeValue('bg.light', 'bg.dark')}
                px='2.5%'
                pb='20px'
                alignItems='center'
                space={5}
            >
                {/* Header */}
                <Box
                    pt='12px'
                >
                    <Text
                        fontSize={{ base: 'sectionHeader.base', lg: 'sectionHeader.lg' }}
                        fontWeight={800}
                        letterSpacing={4}
                        lineHeight={40}
                    >
                        {'Conversational Flow'}
                    </Text>
                    <PresenceTransition visible={pageLoaded} initial={{
                        scale: 0,
                    }} animate={{
                        scale: 1,
                        transition: {
                            duration: 400,
                            delay: 300,
                        }
                    }}>
                        <FullScreenLine />
                    </PresenceTransition>
                </Box>
                {/* Img */}
                <Box
                    w={imgW}
                    borderRadius={10}
                    justifyContent='center'
                    alignItems='center'
                    shadow={4}
                    minW='325px'
                    maxW='500px'
                >
                    <img
                        src={require('../../assets/volleys.png')}
                        width={'100%'}
                    />
                </Box>
            </VStack>
            {/* Body */}
            <VStack
                space={5}
                alignItems='center'
                pb='20px'
            >
                <VStack
                    space={5}
                    w='100%'
                    px={{base: '5%', lg: '10%'}}
                    // alignItems='center'
                >
                    {sectionHeaderText('The Problem')}
                    {bodyText('We collected information regarding who users talked with, but we did not have a meaningful way to express this data. Initially, the information was titled ‘Interjections’ and put in a clustered bar graph series with the number of times the user interjected while each participant was speaking and their respective interjections while the user was speaking forming each cluster. This was a subpar means of showing how people interact with each other since it silos the user’s behavior in a disadvantageous manner; users did not get a sense of how the meeting as a whole went, but rather only their interactions with specific individuals. This significantly limits the usefulness of this graph and undercuts the goal of user’s improving their behavior based upon the post meeting metrics. However, there was a strict requirement not to reveal specific data values of relationships that did not include the user, so we needed a solution which gave users a sense of how everyone interacted without disclosing specific values.')}
                </VStack>
                <VStack
                    space={5}
                    w='100%'
                    px={{base: '5%', lg: '10%'}}
                    // alignItems='center'
                >
                    {sectionHeaderText('The Solution')}
                    {bodyText('I determined that a ribbonless chord diagram would be the best graph type to represent this dataset. An obstacle to using this graph type is that, while the dataset was bidirectional, links on this chart are not. Due to this, I modified the shape of the dataset so that each link was a pairwise relationship with the “value” of the link being the sum of the individual’s interaction with each other user. The value of each link is reflected by their respective thickness. Users could see the specific values for each interaction that they were involved in by hovering over their links. Upon doing so, a custom-made tooltip would appear informing the user of their number of interjections to/from the other person and the total number of interjections shared between the user and the other person.')}
                    {bodyText('This overcomes the issue of not showing specific values because users could only see the raw number of interjections associated with their links; the information tooltip does not appear for links that don’t include the user. However, it still gives users a sense of how the meeting went as a whole since they can view the relative thickness of the links between all participants.')}
                </VStack>
            </VStack>
        </Box>
    );
};

export {
    VolleysProj
};
