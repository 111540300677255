import {
    useColorModeValue,
    VStack,
} from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { NavBar } from '../components/NavBar';
import {
    About,
    Contact,
    Intro,
    Work,
} from './HomePages/index';

interface PageProps {
    pageRef: React.MutableRefObject<HTMLElement | null>;
    isShowing?: boolean;
}

function Home() {
    const introRef = useRef<null | HTMLElement>(null);
    const aboutRef = useRef<null | HTMLElement>(null);
    const workRef = useRef<null | HTMLElement>(null);
    const contactRef = useRef<null | HTMLElement>(null);

    const [ isAboutShowing, setIsAboutShowing ] = useState(false);
    const [ isWorkShowing, setIsWorkShowing ] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, []);

    const scrollHandler = () => {
        if (aboutRef.current && workRef.current) {
            const aboutOffset = 320;
            const workOffset = 160;
            const isThisAbout = window.pageYOffset + window.innerHeight >= aboutRef.current.offsetTop + aboutOffset &&
                window.pageYOffset + window.innerHeight < workRef.current.offsetTop + workOffset;
            const isThisWork = window.pageYOffset + window.innerHeight >= workRef.current.offsetTop + workOffset;

            if (isThisAbout) {
                setIsAboutShowing(true);
                setIsWorkShowing(false);

            }
            else if (isThisWork) {
                setIsAboutShowing(false);
                setIsWorkShowing(true);
            }
            else {
                setIsAboutShowing(false);
                setIsWorkShowing(false);
            }
        }
    };

    return (
        <>
            <VStack
                space={5}
                bg={useColorModeValue('bg.light', 'bg.dark')}
            >
                <NavBar
                    introRef={introRef}
                    workRef={workRef}
                    aboutRef={aboutRef}
                    contactRef={contactRef}
                />
                <Intro
                    pageRef={introRef}
                />
                <About
                    pageRef={aboutRef}
                    isShowing={isAboutShowing}
                />
                <Work
                    pageRef={workRef}
                    isShowing={isWorkShowing}
                />
                <Contact
                    pageRef={contactRef}
                />
            </VStack>
        </>
    );
}

export {
    Home,
    PageProps,
};
