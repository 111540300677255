import {
    Button,
    HStack,
    Text,
    useColorMode,
} from 'native-base';
import React, {useState} from 'react';
import { useNavigate } from 'react-router';
import {
    BsArrowRightSquare,
    BsXSquare,
} from 'react-icons/bs';

interface SubPageButtonProps {
    labelText: string;
    navPath: string;
    disabled: boolean;
}

// initializing colors again here from theme; these match the text colors, but since
// react icons don't allow use of the NativeBase theme, this is a work around
const iconColorLight = '#0f0f0f';
const iconColorDark = '#FFFFFB';

const SubPageButton = (props: SubPageButtonProps) => {
    const navigate = useNavigate();
    const [ isHovering, setIsHovering ] = useState(false);

    const hoverAction = (isHoverIn: boolean) => {
        setIsHovering(isHoverIn);
    };

    const {colorMode} = useColorMode();
    const iconColor = colorMode === 'light' ? iconColorLight : iconColorDark;

    const disabledIcon = (
        <BsXSquare
            color={iconColor}
            size='20px'
        />
    );

    const rightArrowIcon = (
        <BsArrowRightSquare
            className={isHovering ? 'subPageButtonArrow' : ''}
            color={iconColor}
            size='20px'
        />
    );

    const icon = props.disabled ? disabledIcon : rightArrowIcon;

    return (
        <Button
            borderWidth={'1.5px'}
            borderRadius={7.5}
            borderColor={iconColor}
            padding={'7.5px 10px'}
            _light={{
                bg: 'none',
                _pressed: { bg: 'none' },
                _hover: {
                    bg: 'none',
                    shadow: 3,
                },
            }}
            _dark={{
                bg: 'none',
                _pressed: { bg: 'none' },
                _hover: {
                    bg: 'none',
                    shadow: 4,
                },
            }}

            onPress={()=>navigate(props.navPath)}
            onHoverIn={()=>hoverAction(true)}
            onHoverOut={()=>hoverAction(false)}
            isDisabled={props.disabled}
        >
            <HStack
                justifyContent={'space-between'}
                alignItems={'center'}
                space={'10px'}
                paddingX={'5px'}
                w='100%'
            >
                <Text
                    fontSize={{base: '12px', lg: '16px'}}
                >
                    {props.labelText}
                </Text>
                {icon}
            </HStack>
        </Button>
    );
};

export {
    SubPageButton,
};
