import { NavBackHeader } from '../../components/common/NavBackHeader';
import {
    Box,
    useColorModeValue,
    VStack,
    Text,
    PresenceTransition,
    useBreakpointValue,
} from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { FullScreenLine } from '../../components/common/FullscreenLine';

const MobileProj = () => {
    // scroll to top of page after routing
    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    useEffect(() => onTop(), [ routePath ]);

    const [ pageLoaded, setPageLoaded ] = useState(false);
    useEffect(() => setPageLoaded(true), [ pageLoaded ]);

    const pageRef = useRef<null | HTMLElement>(null);

    const imgW = useBreakpointValue({
        base: '100%',
        lg: '50%',
    });

    const sectionHeaderText = (text: string) => {
        return (
            <Text
                fontSize={{base: '24px', lg: '32px'}}
                fontWeight={600}
            >
                {text}
            </Text>
        );
    };

    const bodyText = (text: string) => {
        return (
            <Text
                fontSize={{base: '16px', lg: '20px'}}
                fontWeight={500}
                w='100%'
            >
                {text}
            </Text>
        );
    };

    return(
        <Box
            height='100%'
            minHeight='100vh'
            bg={useColorModeValue('bg.light', 'bg.dark')}
        >
            <Box
                position={'absolute'}
                top={0}
                h={0}
                ref={pageRef}
            />
            <NavBackHeader
                pageRef = {pageRef}
            />
            <VStack
                w='100%'
                h='100%'
                mt={{ base: '50px', lg: '100px' }}
                bg={useColorModeValue('bg.light', 'bg.dark')}
                px='2.5%'
                pb='20px'
                alignItems='center'
                space={5}
            >
                {/* Header */}
                <Box
                    pt='12px'
                >
                    <Text
                        fontSize={{ base: 'sectionHeader.base', lg: 'sectionHeader.lg' }}
                        fontWeight={800}
                        letterSpacing={4}
                        lineHeight={40}
                    >
                        {'Mobile Overhaul'}
                    </Text>
                    <PresenceTransition visible={pageLoaded} initial={{
                        scale: 0,
                    }} animate={{
                        scale: 1,
                        transition: {
                            duration: 400,
                            delay: 300,
                        }
                    }}>
                        <FullScreenLine />
                    </PresenceTransition>
                </Box>
                {/* Img */}
                <Box
                    w={imgW}
                    borderRadius={10}
                    justifyContent='center'
                    alignItems='center'
                    // minW='300px'
                    maxW='600px'
                >
                    <img
                        src={require('../../assets/mobileViews.png')}
                        width={'100%'}
                    />
                </Box>
            </VStack>
            {/* Body */}
            <VStack
                space={5}
                alignItems='center'
                pb='20px'
            >
                <VStack
                    space={5}
                    w='100%'
                    px={{base: '5%', lg: '10%'}}
                >
                    {sectionHeaderText('The Problem')}
                    {bodyText('When I joined the company, the site did not work on mobile; the entire UI broke when the screen size was too small. This is a big problem: modern day websites need to be usable on phones.')}
                </VStack>
                <VStack
                    space={5}
                    w='100%'
                    px={{base: '5%', lg: '10%'}}
                >
                    {sectionHeaderText('The Solution')}
                    {bodyText('I decided to use NativeBase, a light-weight, mobile-first component package. Single-handedly, I was able to convert the website to being mobile-friendly in one and a half weeks. This lead to significantly increased user engagement with the platform on mobile phones.')}
                </VStack>
            </VStack>
        </Box>
    );
};

export {
    MobileProj
};
