import {
    Box,
    Stack,
    VStack,
    useColorModeValue
} from 'native-base';
import { ResumeItem } from '../components/common/ResumeItem';
import React, { useEffect, useRef } from 'react';
import { NavBackHeader } from '../components/common/NavBackHeader';
import { useLocation } from 'react-router';

interface ResumeItemProps {
    imgPath: string;
    companyName: string;
    companyDesc: string;
    jobTitle: string;
    jobDate: string;
    achievements: string[];
}

const Resume = () => {
    const resumeItems: ResumeItemProps[] = [
        {
            imgPath: 'esmeLogo.png',
            companyName: 'Esme Learning Solutions',
            companyDesc: 'Esme Learning Solutions is a B2B2C and B2C executive education platform powered by AI tools. It combines peered-reviewed cognitive and neuroscience research with world-famous educators to provide an immersive and applied learning experience.',
            jobTitle: 'Software Developer',
            jobDate: 'May 2021',
            achievements: [
                'Rebuilt the entire website to be mobile-friendly using NativeBase resulting in significantly greater user engagement and conversion',
                'Pitched, designed, and built a new metric that provides instant behavioral insights to users called “the most exciting feature of  the new release” by VP of Product Engineering',
                'Implemented rebranded designs including transitioning code frameworks, Coffescript to Typescript and MUI to NativeBase',
                'Wire framed multiple design prototypes using Figma for a key part of the UI used by tens of thousands of users',
                'Designed and built a users analytics dashboard using amCharts'
            ]
        },
        {
            imgPath: 'mitLogo.png',
            companyName: 'MIT, McGovern Institute for Brain Research, Kanwisher Lab',
            companyDesc: 'Nancy Kanwisher, a world-renowned computational neuroscientist who has received numerous accolades for her research, investigates the neural and cognitive mechanisms underlying human visual perception and cognition.',
            jobTitle: 'Research Intern',
            jobDate: 'June 2019 — Aug 2019',
            achievements: [
                'Designed and wrote web-based facial-feature-similarity experiments which ran on Amazon’s Mechanical Turk (MTurk) for collecting AI training data',
                'Analyzed MTurk data using MATLAB to find meaningful correlations in the data',
                'Created extensive guide for cognitive scientists to easily use MTurk to run their experiments',
                'Wrote and ran music-familiarity experiment on MTurk for other labs',
            ]
        },
        {
            imgPath: 'cogitoLogo.png',
            companyName: 'Cogito Corporation',
            companyDesc: 'Cogito offers an artificial intelligence coaching system for augmenting the emotional intelligence of phone professionals in order to boost customer retention.',
            jobTitle: 'Intern',
            jobDate: 'July 2017 — Aug 2017',
            achievements: [
                'Investigated the psychological effects of music on the brain to regulate the mood of the Cogito Dialog software users',
            ]
        },
    ];

    const pageRef = useRef<null | HTMLElement>(null);

    // scroll to top of page after routing
    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    useEffect(() => {
        onTop();
    }, [ routePath ]);


    return(
        <Box
            height='100%'
            minHeight='100vh'
            bg={useColorModeValue('bg.light', 'bg.dark')}
        >
            <Box
                position={'absolute'}
                top={0}
                h={0}
                ref={pageRef}
            />
            <NavBackHeader
                pageRef = {pageRef}
            />
            <VStack
                w='100%'
                h='100%'
                mt={{ base: '50px', lg: '100px' }}
                bg={useColorModeValue('bg.light', 'bg.dark')}
                px='2.5%'
                pb='20px'
                alignItems='center'
            >
                <Stack
                    w='100%'
                    h='100%'
                    justifyContent={{ base: 'center', lg: 'space-around' }}
                    alignItems={{ base: 'center', lg: 'flex-end' }}
                    direction={{ base: 'column', lg: 'row' }}
                >
                    <VStack
                        pt='12px'
                        w='100%'
                        space={5}
                    >
                        {resumeItems.map((item: ResumeItemProps, idx: number) => {
                            return (
                                <ResumeItem
                                    {...item}
                                    key={idx}
                                />
                            );
                        })}
                    </VStack>
                </Stack>
            </VStack>
        </Box>
    );
};

export {
    Resume,
    ResumeItemProps,
};
