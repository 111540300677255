import React, { ReactNode } from 'react';
import {
    Button,
    Hidden,
    HStack,
    Menu,
    Text,
    useColorMode,
    VStack
} from 'native-base';

interface NavButtonProps {
    labelText: string;
    clickFunction: () => void;
    icon?: ReactNode;
}

const HeaderButton = (props: NavButtonProps) => {
    const { colorMode } = useColorMode();
    // const [ isHovering, setIsHovering ] = React.useState(props.labelText);

    // const location = useLocation();
    // const currPathClass = location.pathname === props.navRoute.slice(1) ?
    //     'item-is-curr-path' : '';

    return(
        <>
            <Hidden till='lg'>
                <VStack>
                    <Button
                        onPress={() => props.clickFunction()}
                        _light={{
                            bg: 'none',
                            _pressed: { bg: 'none' },
                            _hover: { bg: 'none' },
                            color: 'text.dark',
                        }}
                        _dark={{
                            bg: 'none',
                            _pressed: { bg: 'none' },
                            _hover: { bg: 'none' },
                            color: 'text.light',
                        }}
                    >
                        <Text
                            fontWeight='700'
                            fontFamily='Inter'
                            fontSize='xl'
                        >
                            <div
                                className={`hover-underline-animation-${colorMode}`}
                            >
                                {props.labelText}
                            </div>
                        </Text>

                    </Button>
                </VStack>
            </Hidden>
            <Hidden from='lg'>
                <Menu.Item
                    onPress={() => props.clickFunction()}
                >
                    <HStack
                        alignItems={'center'}
                        space={'2.5'}
                    >
                        {props.icon}
                        <Text
                            fontWeight='600'
                            fontFamily='Inter'
                            fontSize='xl'
                        >
                            {props.labelText}
                        </Text>
                    </HStack>
                </Menu.Item>
            </Hidden>
        </>
    );
};

export {
    HeaderButton,
    NavButtonProps,
};
