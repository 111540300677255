import {
    Box,
    VStack,
    useColorModeValue
} from 'native-base';
import React, { useEffect, useRef } from 'react';
import { NavBackHeader } from '../components/common/NavBackHeader';
import { useLocation } from 'react-router';
import { SubPageButton } from '../components/common/SubPageButton';


const CV = () => {
    const pageRef = useRef<null | HTMLElement>(null);

    // scroll to top of page after routing
    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    useEffect(() => {
        onTop();
    }, [ routePath ]);


    return(
        <Box
            height='100%'
            minHeight='100vh'
            bg={useColorModeValue('bg.light', 'bg.dark')}
        >
            <Box
                position={'absolute'}
                top={0}
                h={0}
                ref={pageRef}
                pt={{base: '50px', lg:'110px'}}
            />
            <NavBackHeader
                pageRef = {pageRef}
            />
            <VStack
                w='100%'
                h='100%'
                mt={{ base: '50px', lg: '100px' }}
                bg={useColorModeValue('bg.light', 'bg.dark')}
                px='2.5%'
                pb='20px'
                alignItems='center'
            >
                <Box
                    w='95%'
                    h='95%'
                    shadow={5}
                    borderRadius={10}
                    mt={'30px'}
                >
                    {/* <img
                        src={require('../assets/cv.png')}
                        width='100%'
                    /> */}
                </Box>
                <SubPageButton
                    navPath='/'
                    disabled={false}
                    labelText='Download CV'
                />
            </VStack>
        </Box>
    );
};

export {
    CV,
};
