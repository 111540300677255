import { PageProps } from '../Home';
import {
    Box,
    Stack,
    useColorMode,
    VStack,
    Text,
    PresenceTransition,
    Hidden,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { FullScreenLine } from '../../components/common/FullscreenLine';
// import { SubPageButton } from '../../components/common/SubPageButton';
import { EsmeWork } from '../EsmeWork';
import { DownloadButton } from '../../components/common/DownloadButton';

const Work = (props: PageProps) => {
    const { colorMode } = useColorMode();
    const [ pageLoaded, setPageLoaded ] = useState(false);

    useEffect(() => setPageLoaded(true), [ pageLoaded ]);

    return (
        <>
            <Box
                ref={props.pageRef}
                h={0}
            />
            <VStack
                bg={colorMode === 'light' ? 'bg.light' : 'bg.dark'}
                justifyContent='top'
                alignItems='center'
                w='100%'
                px={{ base: '2.5%', lg: '10%' }}
                mb='48px'
            >
                <Stack
                    w='100%'
                    alignItems={{ base: 'center', lg: 'flex-start' }}
                >
                    <Hidden from='lg'>
                        <>
                            <VStack
                                mb='5px'
                            >
                                <Text
                                    fontSize={{ base: 'sectionHeader.base', lg: 'sectionHeader.lg' }}
                                    fontWeight={800}
                                    letterSpacing={4}
                                    lineHeight={40}
                                >
                                    {'WORK'}
                                </Text>
                                <PresenceTransition visible={props.isShowing} initial={{
                                    scale: 0
                                }} animate={{
                                    scale: 1,
                                    transition: {
                                        duration: 400,
                                        delay: 100,
                                    }
                                }}>
                                    <FullScreenLine />
                                </PresenceTransition>
                            </VStack>
                            <DownloadButton
                                labelText={'Download CV'}
                                disabled={false}
                            />
                            {/* <SubPageButton
                                labelText={'Experience Overview'}
                                navPath={'/resume'}
                                disabled={false}
                            /> */}
                        </>
                    </Hidden>
                    <Hidden till={'lg'}>
                        <Stack
                            direction={'row'}
                            w={{base: undefined, lg: '100%'}}
                            justifyContent={{base: undefined, lg: 'space-between'}}
                            alignItems='flex-start'
                            h='100%'
                        >
                            <VStack >
                                <Text
                                    fontSize={{ base: 'sectionHeader.base', lg: 'sectionHeader.lg' }}
                                    fontWeight={800}
                                    letterSpacing={4}
                                    lineHeight={40}
                                >
                                    {'WORK'}
                                </Text>
                                <PresenceTransition visible={props.isShowing} initial={{
                                    scale: 0
                                }} animate={{
                                    scale: 1,
                                    transition: {
                                        duration: 400,
                                        delay: 100,
                                    }
                                }}>
                                    <FullScreenLine/>
                                </PresenceTransition>
                            </VStack>
                            <DownloadButton
                                labelText={'Download CV'}
                                disabled={false}
                            />
                        </Stack>
                    </Hidden>
                </Stack>
                <EsmeWork />
            </VStack>
        </>
    );
};

export {
    Work,
};
