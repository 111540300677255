import React from 'react';
import Main from './Main';
import './index.css';

function App() {
    return (
        <Main/>
    );
}

export {
    App,
};
