import { NavBackHeader } from '../../components/common/NavBackHeader';
import {
    Box,
    useColorModeValue,
    VStack,
    Text,
    PresenceTransition,
    useBreakpointValue,
    // HStack,
} from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { FullScreenLine } from '../../components/common/FullscreenLine';

const NarrativeProj = () => {
    // scroll to top of page after routing
    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    useEffect(() => onTop(), [ routePath ]);

    const [ pageLoaded, setPageLoaded ] = useState(false);
    useEffect(() => setPageLoaded(true), [ pageLoaded ]);

    const pageRef = useRef<null | HTMLElement>(null);

    const imgW = useBreakpointValue({
        base: '90%',
        lg: '80%',
    });
    imgW;

    const sectionHeaderText = (text: string) => {
        return (
            <Text
                fontSize={{base: '24px', lg: '32px'}}
                fontWeight={600}
            >
                {text}
            </Text>
        );
    };

    const bodyText = (text: string) => {
        return (
            <Text
                fontSize={{base: '16px', lg: '20px'}}
                fontWeight={500}
                w='100%'
            >
                {text}
            </Text>
        );
    };

    return(
        <Box
            height='100%'
            minHeight='100vh'
            bg={useColorModeValue('bg.light', 'bg.dark')}
        >
            <Box
                position={'absolute'}
                top={0}
                h={0}
                ref={pageRef}
            />
            <NavBackHeader
                pageRef = {pageRef}
            />
            <VStack
                w='100%'
                h='100%'
                mt={{ base: '50px', lg: '100px' }}
                bg={useColorModeValue('bg.light', 'bg.dark')}
                px='2.5%'
                pb='20px'
                alignItems='center'
                space={5}
            >
                {/* Header */}
                <Box
                    pt='12px'
                >
                    <Text
                        fontSize={{ base: 'sectionHeader.base', lg: 'sectionHeader.lg' }}
                        fontWeight={800}
                        letterSpacing={4}
                        lineHeight={40}
                    >
                        {'Meeting Narrative'}
                    </Text>
                    <PresenceTransition visible={pageLoaded} initial={{
                        scale: 0,
                    }} animate={{
                        scale: 1,
                        transition: {
                            duration: 400,
                            delay: 300,
                        }
                    }}>
                        <FullScreenLine />
                    </PresenceTransition>
                </Box>
                {/* Img */}
                <Box
                    w={'100%'}
                    px={{base: '5%', lg: '10%'}}
                    borderRadius={10}
                    justifyContent='center'
                    alignItems='center'
                    shadow={4}
                >
                    <img
                        src={require('../../assets/narrative.png')}
                        width={'100%'}
                    />
                </Box>
            </VStack>
            {/* Body */}
            <VStack
                space={10}
                alignItems='center'
                pb='20px'
            >
                <VStack
                    space={5}
                    w='100%'
                    px={{base: '5%', lg: '10%'}}
                    // alignItems='center'
                >
                    {sectionHeaderText('The Problem')}
                    {bodyText('An issue that the company faced was that users were not engaged with the post-meeting Riff Analytics dashboard. When users went to the dashboard, they would be on the page for a very short time duration before navigating away. Prior to this feature, the dashboard only consisted of graphs which were hard for the average user to understand. The graphs had paragraph-long explanations about what was being calculated and what desired behavior was, but these explanations were hidden behind tooltips which required user engagement with the platform. We needed a way to give users as much information as possible in an easily digestible format that any user could understand.')}
                </VStack>
                {/* <HStack w='30%'
                    justifyContent={'space-evenly'}>
                    <Box w='20%'><FullScreenLine/></Box>
                    <Box w='20%'><FullScreenLine/></Box>
                    <Box w='20%'><FullScreenLine/></Box>
                </HStack> */}
                <VStack
                    space={5}
                    w='100%'
                    px={{base: '5%', lg: '10%'}}
                    // alignItems='center'
                >
                    {sectionHeaderText('The Solution')}
                    {bodyText('I picked up on this issue and pitched a new feature: the Meeting Narrative. The Narrative combines information from all of the other graph types in order to provide users with an overview of their performance in a meeting. However, the Narrative doesn’t just restate what is already said in each individual graph; it draws connections between each dataset which only exceptionally observant users would pick up on. I sought out to answer four primary questions about how a user interacted in a meeting:')}
                    <VStack w='100%'>
                        {bodyText('- Were they an active participant?')}
                        {bodyText('- Did they pause enough to allow others to speak?')}
                        {bodyText('- Did they speak with everyone in the meeting?')}
                        {bodyText('- Who were they most engaged with in the meeting? ')}
                    </VStack>
                    {bodyText('These questions were deemed to be the most significant pieces of information that we wanted users to take away from the metrics on the dashboard. However, for the majority of them, we did not have metrics which directly answered each question. In order to build this feature, I worked closely with our data scientist to create new algorithms which interpreted our preexisting data in a new manner which was still deemed statistically significant and an accurate reflection of the behavioral research the rest of the metrics were based upon.')}
                    {bodyText('I designed the component with several aspects things in mind: readability, digestibility, and continuity with the rest of the platform’s UI.')}
                    {bodyText('The component lead to significantly increased user interaction with the analytics dashboard and longer retention durations on the page itself.')}
                </VStack>
            </VStack>
        </Box>
    );
};

export {
    NarrativeProj
};
