import {
    useColorMode,
    HStack,
    Button,
    Text,
} from 'native-base';
import React, { useState } from 'react';
import { BsArrowDownSquare } from 'react-icons/bs';
import { Link } from 'react-router-dom';

interface DownloadButtonProps {
    labelText: string;
    disabled: boolean;
}

// initializing colors again here from theme; these match the text colors, but since
// react icons don't allow use of the NativeBase theme, this is a work around
const iconColorLight = '#0f0f0f';
const iconColorDark = '#FFFFFB';

const DownloadButton = (props: DownloadButtonProps) => {
    const {colorMode} = useColorMode();
    const iconColor = colorMode === 'light' ? iconColorLight : iconColorDark;

    const [ isHovering, setIsHovering ] = useState(false);

    const hoverAction = (isHoverIn: boolean) => {
        console.log('hover');
        setIsHovering(isHoverIn);
    };

    return (
        <Link
            to={process.env.PUBLIC_URL + '/OliverMillard_CV.png'}
            target="_blank"
            style={{ textDecoration: 'none' }}
            download
        >
            <Button
                borderWidth={'1.5px'}
                borderRadius={7.5}
                borderColor={iconColor}
                padding={'7.5px 10px'}
                _light={{
                    bg: 'none',
                    _pressed: { bg: 'none' },
                    _hover: {
                        bg: 'none',
                        shadow: 3,
                    },
                }}
                _dark={{
                    bg: 'none',
                    _pressed: { bg: 'none' },
                    _hover: {
                        bg: 'none',
                        shadow: 4,
                    },
                }}
                onHoverIn={()=>hoverAction(true)}
                onHoverOut={()=>hoverAction(false)}
                isDisabled={props.disabled}
            >
                <HStack
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    space={'10px'}
                    paddingX={'5px'}
                    w='100%'
                >
                    <Text
                        fontSize={{base: '12px', lg: '16px'}}
                    >
                        {props.labelText}
                    </Text>
                    {<BsArrowDownSquare
                        className={isHovering ? 'downloadArrow' : ''}
                        color={iconColor}
                        size='20px'
                    />}
                </HStack>
            </Button>
        </Link>
    );
};

export {
    DownloadButton
};
