
import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import React, { useLayoutEffect } from 'react';
import { useColorMode } from 'native-base';


const SkillChart = () => {

    const { colorMode } = useColorMode();
    const blackOnLight = colorMode === 'light' ? '#000000' : '#ffffff';
    // const invisible = colorMode === 'light' ? '#ffffff' : '#181818';

    useLayoutEffect(() => {
        const root = am5.Root.new('skillChart');
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        const chart = root.container.children.push(
            am5radar.RadarChart.new(root, {
                layout: root.verticalLayout,
                // innerRadius: am5.percent(1) // <- hole in the center
            })
        );

        // chart.set('cursor', am5radar.RadarCursor.new(root, {
        //     tooltipText: 'hi'
        // }));

        chart.children.unshift(am5.Label.new(root, {
            text: 'My Favorite Stack',
            fontSize: 36,
            fontWeight: '200',
            textAlign: 'center',
            fill: am5.color(blackOnLight),
            x: am5.percent(50),
            centerX: am5.percent(50),
            paddingTop:  0,  // prev  20,
            paddingBottom: 0,
            marginTop: 0,
        }));

        // Create Y-axis
        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5radar.AxisRendererRadial.new(root, {
                    minGridDistance: 30,
                }),
            })
        );

        yAxis.get('renderer').labels.template.setAll({
            visible: false
        });

        yAxis.get('renderer').grid.template?.setAll({
            stroke: am5.color(blackOnLight),
            // strokeWidth: 2,
            // strokeOpacity: 0.3,
        });

        // Create X-Axis
        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: 'skill',
                maxDeviation: 0,
                renderer: am5radar.AxisRendererCircular.new(root, {
                    minGridDistance: 50,
                    cellStartLocation: 0,
                    cellEndLocation: 0,

                    // fill: am5.color('#ffffff'),
                })
            })
        );

        xAxis.get('renderer').labels.template.setAll({
            fontSize: 16,
            fontWeight: '600',
            fill: am5.color(blackOnLight),
        });

        xAxis.get('renderer').grid.template.setAll({
            stroke: am5.color(blackOnLight),
        });

        const series = chart.series.push(
            am5radar.SmoothedRadarLineSeries.new(root, {
                name: 'series',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'level',
                categoryXField: 'skill',
                // fill: am5.color(blackOnLight),
                opacity: .75,
            })
        );

        series.fills.template.setAll({
            visible: true,
            fillOpacity: .9,
        });

        series.strokes.template.setAll({
            strokeOpacity: 0
        });

        const data = [
            {
                'skill': 'React',
                'level': 9.8,
            },
            {
                'skill': 'TypeScript',
                'level': 9.4,
            },
            {
                'skill': 'Figma',
                'level': 8.5,
            },
            {
                'skill': 'Redux',
                'level': 7,
            },
            {
                'skill': 'SwiftUI',
                'level': 8,
            },
            {
                'skill': 'NativeBase',
                'level': 9.9,
            },
            {
                'skill': 'Git',
                'level': 8.4,
            },
            {
                'skill': 'amCharts',
                'level': 9.5,
            },
            {
                'skill': 'Python',
                'level': 5.3,
            },
        ];
        series.data.setAll(data);
        xAxis.data.setAll(data);

        return () => {
            root.dispose();
        };
    }, [ colorMode ]);

    return (
        <div id="skillChart" style={{ width: '100%', height: '450px' }}></div>
    );
};

export {
    SkillChart,
};
