import { PageProps } from '../Home';
import { FullScreenLine } from '../../components/common/FullscreenLine';
import {
    Stack,
    Text,
    VStack,
    useBreakpointValue,
    useColorModeValue,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Contact = (props: PageProps) => {
    const [ pageLoaded, setPageLoaded ] = useState(false);

    // const [ hoveringEmail, setHoveringEmail ] = useState(false);
    // const [ hoveringLinkedIn, setHoveringLinkedIn ] = useState(false);

    useEffect(() => setPageLoaded(true), [ pageLoaded ]);

    const linesArr = [];
    const numLines = useBreakpointValue({
        base: '3',
        md: '4',
        lg: '5',
    });

    for (let i = 0; i < numLines; i++) {
        linesArr.push(<FullScreenLine key={`${i}-line`}/>);
    }

    return (
        <>
            <VStack
                bg={useColorModeValue('bg.light', 'bg.dark')}
                height={{base: '180px', lg:'275px'}}
                alignItems='center'
                px='3.5%'
                ref={props.pageRef}
            >
                <VStack
                    w='100%'
                    h='100%'
                    space={1}
                    alignItems='center'
                    justifyContent='flex-end'
                >
                    <Stack
                        w='100%'
                        h='100%'
                        justifyContent={{base: 'flex-end', lg: 'space-around'}}
                        alignItems={{base: 'center', lg: 'flex-end'}}
                        direction={{ base: 'column', lg: 'row' }}
                    >
                        <Text
                            fontSize={{ base: '9vw', md: '11vw', lg: '9vw' }}
                            fontWeight={700}
                            letterSpacing={{base: '6', lg: '8'}}
                            lineHeight={{base: undefined, lg: '8.5vw'}}
                            w={{ base: '100%', lg: '80%' }}
                            textAlign={{base: 'center', lg: undefined}}
                        >
                            {'GET IN TOUCH'}
                        </Text>
                        <Stack
                            w={{base: '100%', lg: '20%'}}
                            alignItems={'flex-end'}
                        >
                            <Stack
                                w={{base: '100%', lg: '90%'}}
                                h='100%'
                                alignItems={{base: 'center', lg: 'flex-start'}}
                                direction={{base: 'row', lg: 'column'}}
                                justifyContent={'space-evenly'}
                            >
                                <div
                                    className='gelatine'
                                >
                                    <Link
                                        to='#'
                                        onClick={(e) => {
                                            window.location.href = 'mailto:olivermillard@gmail.com';
                                            e.preventDefault();
                                        }}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Text
                                            fontSize={{base: '5vw', lg: '2vw'}}
                                            fontWeight={600}
                                            letterSpacing={{base: '6', lg: '8'}}
                                            textAlign={{ base: 'center', lg: undefined }}
                                        >
                                            {'•Email'}
                                        </Text>
                                    </Link>
                                </div>
                                <div
                                    className='gelatine'
                                >
                                    <Link
                                        to='#'
                                        onClick={(e) => {
                                            window.open('https://www.linkedin.com/in/oliver-millard-44a06283/');
                                            e.preventDefault();
                                        }}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Text
                                            fontSize={{ base: '5vw', lg: '2vw' }}
                                            fontWeight={600}
                                            letterSpacing={{ base: '6', lg: '8' }}
                                        >
                                            {'•LinkedIn'}
                                        </Text>
                                    </Link>
                                </div>
                            </Stack>
                        </Stack>
                    </Stack>
                    {linesArr}
                </VStack>
            </VStack>
        </>
    );
};

export {
    Contact,
};
