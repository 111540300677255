import { PageProps } from '../Home';
import { FullScreenLine } from '../../components/common/FullscreenLine';
import {
    Box,
    PresenceTransition,
    Stack,
    Text,
    useColorMode,
    VStack,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { SkillChart } from '../../components/SkillChart';

const About = (props: PageProps) => {
    const { colorMode } = useColorMode();
    const [ pageLoaded, setPageLoaded ] = useState(false);

    useEffect(() => setPageLoaded(true), [ pageLoaded ]);

    const aboutText = (text: string) => {
        return (
            <Text
                fontSize={{base: '16px', lg: '20px'}}
                fontWeight={500}
            >
                {text}
            </Text>
        );
    };

    const waveColor = colorMode === 'light' ? '#F9F3E9' : '#1F1F1F';

    return (
        <>
            <Box
                ref={props.pageRef}
                h={0}
            />
            <svg viewBox="0 0 1440 320">
                <path
                    fill={waveColor}
                    fillOpacity="1"
                    d="M0,224L48,208C96,192,192,160,288,165.3C384,171,480,213,576,234.7C672,256,768,256,864,224C960,192,1056,128,1152,112C1248,96,1344,128,1392,144L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                </path>
            </svg>
            <VStack
                bg={waveColor}
                justifyContent='space-around'
                alignItems='flex-start'
                h='100%'
                px={{base: '2.5%', lg:'10%'}} // formerly lg: 20%
            >
                <VStack
                    w='100%'
                    alignItems='center'
                >
                    <VStack
                        w='100%'
                        h='100%'
                        justifyContent={{ base: 'center', lg: 'space-around' }}
                        alignItems={{ base: 'center', lg: 'flex-start' }}
                    >
                        <Box>
                            <Text
                                fontSize={{ base: 'sectionHeader.base', lg: 'sectionHeader.lg' }}
                                fontWeight={800}
                                letterSpacing={4}
                                lineHeight={40}
                            >
                                {'ABOUT'}
                            </Text>
                            <PresenceTransition visible={props.isShowing} initial={{
                                scale: 0,
                            }} animate={{
                                scale: 1,
                                transition: {
                                    duration: 400,
                                // delay: 100,
                                }
                            }}>
                                <FullScreenLine />
                            </PresenceTransition>
                        </Box>
                    </VStack>
                    <Stack
                        w='100%'
                        h='100%'
                        justifyContent={{ base: 'center', lg: 'space-around' }}
                        alignItems={{ base: 'center', lg: 'flex-start' }}
                        direction={{ base: 'column', lg: 'row' }}
                    >
                        <VStack
                            space={5}
                            py={'16px'}
                            w={{base: '100%', lg: '60%'}}
                            px={{base: '10%', lg: undefined}}
                        >
                            {aboutText(
                                'I am a front-end / UI developer with 2+ years of experience building beautiful and responsive websites and 1+ year of iOS development.'
                            )}
                            {aboutText(
                                'Beyond programming, I am also proficient in Figma and UI / UX design.'
                            )}
                            {/* {aboutText(
                                'I thrive in fast-paced, agile environments where I can collaborate well with my teammates to build meaningful and impactful products.'
                            )} */}
                            {aboutText(
                                'Out of the office you can find me playing guitar and piano, sailing, scuba diving, and traveling the world.'
                            )}
                        </VStack>

                        <VStack
                            h='100%'
                            w={{base: '100%', lg: '40%'}}
                            alignItems={'center'}
                            // direction={{ base: 'column', lg: 'row' }}
                            justifyContent={'center'}

                        >
                            <Box
                                w={{base: '100%', lg: '100%'}}
                                h={{base: '400px', lg: '500px'}}
                            >
                                <SkillChart />
                            </Box>
                        </VStack>
                    </Stack>
                </VStack>
            </VStack>
            <svg viewBox="0 0 1440 320">
                <path
                    fill={waveColor}
                    fillOpacity="1"
                    d="M0,128L48,144C96,160,192,192,288,170.7C384,149,480,75,576,48C672,21,768,43,864,85.3C960,128,1056,192,1152,224C1248,256,1344,256,1392,256L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
                </path>
            </svg>
        </>
    );
};

export {
    About,
};
