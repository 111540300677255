import React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App }  from './App';
import {NativeBaseProvider, extendTheme} from 'native-base';
import reportWebVitals from './reportWebVitals';
import 'inter-ui/inter.css';

const getColorMode = (): string => {
    const userSetColor = localStorage.getItem('userColorMode');

    if(!userSetColor){
        localStorage.setItem('userColorMode', 'dark');
        return 'dark';
    }

    return userSetColor;
};

const theme = extendTheme({
    config: {
        initialColorMode: getColorMode(),
    },

    // Make sure values below matches any of the keys in `fontConfig`
    fonts: {
        heading: 'Inter',
        body: 'Inter',
        mono: 'Inter',
    },

    colors: {
        bg: {
            light: '#FFFFFB',
            dark: '#0f0f0f',
        },
        wave: {
            light: '#F9F3E9',
            dark: '#1F1F1F',
        },
        text: {
            light: '#0f0f0f',
            dark: '#FFFFFB',
        },
    },

    fontSizes: {
        sectionHeader: {
            base: '30px',
            lg: '48px',
        },
        subHeader: {
            base: '27.5px',
            lg: '35px',
        }
    }
});

type MyThemeType = typeof theme;
declare module 'native-base' {
    // eslint-disable-next-line no-unused-vars
    type ICustomTheme = MyThemeType
}

ReactDOM.render(
    <NativeBaseProvider theme={theme}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </NativeBaseProvider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
