/* ******************************************************************************
 * NavBar.tsx                                                                   *
 * ******************************************************************************
 *
 * @fileoverview The top bar component
 *
 * Created on       July ?, 2022
 * @author          Oliver Millard
 *
 * ******************************************************************************/

import {
    HStack,
    useColorMode,
    Text,
    VStack,
    Menu,
    Pressable,
    Box,
    Button,
    useBreakpointValue,
} from 'native-base';
import React, { ReactNode } from 'react';
import { HeaderButton } from './common/HeaderButton';
import { Link } from 'react-router-dom';
import {
    FaLinkedin,
    FaEnvelope,
    FaSun,
    FaMoon,
    FaHome,
    FaInfoCircle,
    FaBriefcase
} from 'react-icons/fa';
import { RiMenuFill } from 'react-icons/ri';

interface HamburgerMenuProps extends introRefProps{
    handleNavClick: (pageRef: React.MutableRefObject<HTMLElement | null>) => void;
    navItems: NavItem[];
}

interface NavItem {
    labelText: string;
    pageRef: React.MutableRefObject<HTMLElement | null>;
    icon?: ReactNode;
}

interface introRefProps {
    introRef: React.MutableRefObject<HTMLElement | null>;
    workRef: React.MutableRefObject<HTMLElement | null>;
    aboutRef: React.MutableRefObject<HTMLElement | null>;
    contactRef: React.MutableRefObject<HTMLElement | null>;
}

// initializing colors again here from theme; these match the text colors, but since
// react icons don't allow use of the NativeBase theme, this is a work around
const iconColorLight = '#0f0f0f';
const iconColorDark = '#FFFFFB';

const NavBar = (props: introRefProps) => {
    const {colorMode} = useColorMode();
    const iconColor = colorMode === 'light' ? iconColorLight : iconColorDark;

    const isLg = useBreakpointValue({
        base: false,
        lg: true,
    });

    const barIconSize = useBreakpointValue({
        base: '24px',
        lg: '30px',
    });

    const navItems: NavItem[] = [
        {
            labelText: 'Home',
            pageRef: props.introRef,
            icon: (
                <FaHome
                    size={ 20}
                    color={iconColor}
                />
            )
        },
        {
            labelText: 'About',
            pageRef: props.aboutRef,
            icon: (
                <FaInfoCircle
                    size={20}
                    color={iconColor}
                />
            )
        },
        {
            labelText: 'Work',
            pageRef: props.workRef,
            icon: (
                <FaBriefcase
                    size={20}
                    color={iconColor}
                />
            )
        },
        {
            labelText: 'Contact',
            pageRef: props.contactRef,
            icon: (
                <FaEnvelope
                    size={20}
                    color={iconColor}
                />
            )
        }
    ];

    const navOptions = isLg ? (
        navItems.map((item: NavItem, idx: number) => {
            return (
                <HeaderButton
                    labelText={item.labelText}
                    clickFunction={() => handleScrollNavClick(item.pageRef)}
                    key={idx}
                />
            );
        })
    ) : (
        HamburgerMenu({
            ...props,
            handleNavClick: handleScrollNavClick,
            navItems
        })
    );

    return (
        <HStack
            position='fixed'
            bg={colorMode === 'light' ? 'bg.light' : 'bg.dark'}
            space={3}
            alignItems='center'
            justifyContent='center'
            w='100%'
            h={{base: '50px', lg: '110px'}}
            zIndex='100'
            opacity={'90%'}
        >
            <HStack
                justifyContent='left'
                alignItems='center'
                w='40vw'
                // pl={{base: '30px', lg: '40px'}}
                pl={{base: '5%',lg: '2.5%'}}
            >
                {navOptions}
            </HStack>
            <HStack
                justifyContent='center'
                alignItems='center'
                w='20vw'
                h='100%'
            >
                <VStack
                    // pt='10px'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Button
                        onPress={() => handleScrollNavClick(props.introRef)}
                        _light={{
                            bg: 'none',
                            _pressed: { bg: 'none' },
                            _hover: { bg: 'none' },
                            color: 'text.dark',
                        }}
                        _dark={{
                            bg: 'none',
                            _pressed: { bg: 'none' },
                            _hover: { bg: 'none' },
                            color: 'text.light',
                        }}
                    >
                        <Text
                            textAlign={'center'}
                            fontWeight='bold'
                            fontSize={{base: '25px', lg: '30px'}}
                            lineHeight={{base: '0px', lg: '25px'}}
                        >
                            <div className={isLg ? 'wave' : ''}>
                                {isLg ? ('O B\nM') : ('O B M')}
                            </div >
                        </Text>
                    </Button>
                </VStack>
            </HStack>
            <HStack
                justifyContent='right'
                alignItems='center'
                w='40vw'
                h='100%'
                pr= {{base: '5%',lg: '2.5%'}}// {{base: '30px', lg: '40px'}}
                space={5}
            >
                <Link
                    to='#'
                    onClick={(e) => {
                        window.location.href = 'mailto:olivermillard@gmail.com';
                        e.preventDefault();
                    }}
                >
                    <FaEnvelope
                        size={barIconSize}
                        color={iconColor}
                        className='gelatine'
                    />
                </Link>
                <Link
                    to='#'
                    onClick={(e) => {
                        window.open('https://www.linkedin.com/in/oliver-millard-44a06283/');
                        e.preventDefault();
                    }}
                >
                    <FaLinkedin
                        size={barIconSize}
                        color={iconColor}
                        className='gelatine'
                    />
                </Link>
                {isLg ?
                    <ToggleDarkMode /> : <></>
                }
            </HStack>
        </HStack>
    );
};

const HamburgerMenu = (props: HamburgerMenuProps) => {
    const { colorMode, toggleColorMode } = useColorMode();
    const otherColorModeName = colorMode === 'light' ? 'Dark' : 'Light';
    const iconColor = colorMode === 'light' ? iconColorLight : iconColorDark;

    return (
        <Box
            w="90%"
            mt='5px' // offset the header
            alignItems="flex-start"
        >
            <Menu
                w="180"
                trigger={triggerProps => {
                    return (
                        <Pressable
                            accessibilityLabel="Navigation menu"
                            {...triggerProps}
                        >
                            <RiMenuFill
                                size={'24'}
                                color={iconColor}
                            />
                            {/* <UseAnimations
                                animation={menu2}
                                strokeColor={useColorModeValue('text.dark', 'text.light')}

                                reverse={menuClicked}
                                onMouseOver={() => {
                                    setMenuClicked(!menuClicked);
                                }}
                            /> */}
                        </Pressable>
                    );
                }}>
                {props.navItems.map((item: NavItem, idx: number) => {
                    return (
                        <HeaderButton
                            labelText={item.labelText}
                            clickFunction={() => props.handleNavClick(item.pageRef)}
                            icon={item.icon}
                            key={idx}
                        />
                    );
                })}
                <HeaderButton
                    labelText={`${otherColorModeName} Mode`}
                    clickFunction={() => {
                        toggleColorMode();
                        const setVal = colorMode === 'light' ? 'dark' : 'light';
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        localStorage.setItem('userColorMode', setVal);
                    }}
                    icon={colorMode === 'light' ?
                        (<FaMoon
                            size={20}
                            color={iconColor}
                        />)
                        :
                        (<FaSun
                            size={20}
                            color={iconColor}
                        />)
                    }
                />
            </Menu>
        </Box>
    );
};

function ToggleDarkMode() {
    const { colorMode, toggleColorMode } = useColorMode();
    const iconColor = colorMode === 'light' ? iconColorLight : iconColorDark;

    const barIconSize = useBreakpointValue({
        base: '24px',
        lg: '26px',
    });

    return (
        <Button
            onPress={() => {
                toggleColorMode();
                const setVal = colorMode === 'light' ? 'dark' : 'light';
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                localStorage.setItem('userColorMode', setVal);
            }}
            _light={{
                bg: 'none',
                _pressed: { bg: 'none' },
                _hover: { bg: 'none' },
                color: 'text.dark',
            }}
            _dark={{
                bg: 'none',
                _pressed: { bg: 'none' },
                _hover: { bg: 'none' },
                color: 'text.light',
            }}
            w='0px'
            h='0px'
            mb='4px'
        >
            {colorMode === 'light' ?
                (<FaMoon
                    size={barIconSize}
                    color={iconColor}
                    className='gelatine'
                />)
                :
                (<FaSun
                    size={barIconSize}
                    color={iconColor}
                    className='gelatine'
                />)
            }
        </Button>
    );
}

const handleScrollNavClick = (pageRef: React.MutableRefObject<HTMLElement | null>) => {


    if(pageRef.current){
        window.scrollTo({
            behavior: 'smooth',
            top: pageRef.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 110,
        });
    }

    // pageRef.current?.scrollIntoView({
    //     behavior: 'smooth',

    // });
};

export {
    NavBar,
    introRefProps,
    ToggleDarkMode,
    handleScrollNavClick,
};
