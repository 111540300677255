import { PageProps } from '../Home';
import { ScrollToSeeMore } from '../../components/ScrollToSeeMore';
import {
    Text,
    HStack,
    useColorMode,
    VStack,
    Stack,
    PresenceTransition,
    Box,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { RibbonlessChordDiagram } from '../../components/RibbonlessChordDiagram';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Intro = (props: PageProps) => {
    const { colorMode } = useColorMode();
    const [ pageLoaded, setPageLoaded ] = useState(false);

    useEffect(() => setPageLoaded(true), [ pageLoaded ]);

    return (
        <>
            {/* Put empty box at top for ref so it goes to top of page */}
            <Box
                position={'absolute'}
                top={0}
                h={0}
                ref={props.pageRef}
            />
            <VStack
                bg={colorMode === 'light' ? 'bg.light' : 'bg.dark'}
                justifyContent='top'
                w='100%'
                px='2.5%'
            >
                <Stack
                    space={5}
                    alignItems='center'
                    direction={{ base: 'column', lg: 'row' }}
                    pt={{ base: '60px', lg: '110px' }} // offsets the height of the header (110px)
                >
                    <VStack
                        w={{ base: '100%', lg: '60%' }}
                    >
                        <HStack>
                            <PresenceTransition visible={pageLoaded} initial={{
                                opacity: 0
                            }} animate={{
                                opacity: 1,
                                transition: {
                                    duration: 650,
                                    delay: 100,
                                }
                            }}>
                                <Text
                                    fontSize={{ base: 'sectionHeader.base', lg: 'sectionHeader.lg', }}
                                    letterSpacing={'3'}
                                    fontWeight='light'
                                >
                                    {'Hello.'}
                                </Text>
                            </PresenceTransition>
                            <PresenceTransition visible={pageLoaded} initial={{
                                opacity: 0
                            }} animate={{
                                opacity: 1,
                                transition: {
                                    duration: 650,
                                    delay: 400,
                                }
                            }}>
                                <Text
                                    fontSize={{ base: 'sectionHeader.base', lg: 'sectionHeader.lg', }}
                                    letterSpacing={'3'}
                                    fontWeight='light'
                                >
                                    {' I’m'}
                                    <Text
                                        fontSize={{ base: 'sectionHeader.base', lg: 'sectionHeader.lg', }}
                                        letterSpacing={'5'}
                                        fontWeight='bold'
                                    >
                                        {' Oliver'}
                                        <Text
                                            fontSize={{ base: 'sectionHeader.base', lg: 'sectionHeader.lg', }}
                                            letterSpacing={'0'}
                                            fontWeight='light'
                                        >
                                            {'.'}
                                        </Text>
                                    </Text>
                                </Text>
                            </PresenceTransition>
                        </HStack>
                        <PresenceTransition visible={pageLoaded} initial={{
                            opacity: 0
                        }} animate={{
                            opacity: 1,
                            transition: {
                                duration: 600,
                                delay: 700,
                            }
                        }}>
                            <Text
                                fontSize={{ base: 'sectionHeader.base', lg: 'sectionHeader.lg', }}
                                letterSpacing={'3'}
                                fontWeight='light'
                            >
                                {'A developer.'}
                            </Text>
                        </PresenceTransition>
                        <PresenceTransition visible={pageLoaded} initial={{
                            opacity: 0
                        }} animate={{
                            opacity: 1,
                            transition: {
                                duration: 400,
                                delay: 1200,
                            }
                        }}>
                            <Text
                                fontSize={{ base: 'subHeader.base', lg: 'subHeader.lg' }}
                                letterSpacing='.5'
                                fontWeight='hairline'
                            >
                                {'I\'m a front-end developer with an interest in design.'}
                            </Text>
                        </PresenceTransition>
                    </VStack>
                    <HStack
                        w={{ base: '100%', lg: '40%' }}
                        justifyContent={{ base: 'center' }}
                    >
                        <RibbonlessChordDiagram />
                    </HStack>
                </Stack>
            </VStack>
            <PresenceTransition visible={pageLoaded} initial={{
                opacity: 0
            }} animate={{
                opacity: 1,
                transition: {
                    duration: 400,
                    delay: 1600,
                }
            }}>
                <ScrollToSeeMore />
            </PresenceTransition>
        </>
    );
};

export {
    Intro,
};
