
import { ResumeItemProps } from '@/pages/Resume';
import {
    Box,
    Button,
    Center,
    Hidden,
    HStack,
    Stack,
    Text,
    Tooltip,
    useColorMode,
    useColorModeValue,
    VStack,
} from 'native-base';

import { FaInfoCircle } from 'react-icons/fa';
import React from 'react';

// initializing colors again here from theme; these match the text colors, but since
// react icons don't allow use of the NativeBase theme, this is a work around
const iconColorLight = '#0f0f0f';
const iconColorDark = '#FFFFFB';

const ResumeItem = (props: ResumeItemProps) => {
    return (
        <Stack
            direction={{base: 'column', lg: 'row'}}
            bg={useColorModeValue('wave.light', 'wave.dark')}
            py='10px'
            px='20px'
            borderRadius='10'
            alignItems={{base: 'center', lg: 'center'}}
            justifyContent={{ base: 'center', lg: 'space-between' }}
            space={5}
        >
            <Stack
                justifyContent={'center'}
                alignItems={'center'}
                w={{base:'100%', lg: '12%'}}
            >
                <Center
                    bg='white'
                    p='10px'
                    borderRadius='10'
                    height='103px'
                    width={{base: '100%', lg: '120px'}}
                    shadow={3}
                >
                    <Hidden till='lg'>
                        <img
                            src={require(`../../assets/${props.imgPath}`)}
                            width='100%'
                        />
                    </Hidden>
                    <Hidden from='lg'>
                        <img
                            src={require(`../../assets/${props.imgPath}`)}
                            height='80%'
                        />
                    </Hidden>
                </Center>
            </Stack>
            <VStack
                width={{base: '100%', lg: '85%'}}
            >
                <VStack
                    space={2}
                    width='100%'
                >
                    <HStack
                        justifyContent={'space-between'}
                        alignItems={'flex-start'}
                        space={2}
                        maxWidth='100%'
                    >
                        <Text
                            fontSize={{base: '24px', lg: '30px'}}
                            fontWeight='700'
                        >
                            {props.companyName}
                        </Text>
                        {DescTooltip(props.companyDesc)}
                    </HStack>
                </VStack>
                <HStack
                    space={2}
                    alignItems='center'
                    justifyContent={'space-between'}
                    mb='10px'
                    w='100%'
                >
                    <Box>
                        <Text
                            fontWeight='500'
                            fontSize={{base: '20px', lg: '24px'}}
                        >
                            {props.jobTitle}
                        </Text>
                        <Hidden from='lg'>
                            <Text
                                fontWeight='light'
                                fontSize='16px'
                            >
                                {`${props.jobDate}`}
                            </Text>
                        </Hidden>
                    </Box>
                    <Hidden till='lg'>
                        <Text
                            fontWeight='light'
                            fontSize='20px'
                        >
                            {`${props.jobDate}`}
                        </Text>
                    </Hidden>
                </HStack>
                {props.achievements.map((entry: string, idx: number) => {
                    return (
                        <HStack
                            alignItems={'flex-start'}
                            key={idx}
                            space={1}
                            mb='8px'
                            w='100%'
                        >
                            <Text
                                fontWeight={'500'}
                                fontSize={'16px'}
                            >
                                {'◈'}
                            </Text>
                            <Text
                                fontWeight={'500'}
                                fontSize={'16px'}
                            >
                                {entry}
                            </Text>
                        </HStack>
                    );
                })}
            </VStack>
        </Stack>
    );
};

function DescTooltip(companyDesc: string) {
    const {colorMode} = useColorMode();
    const iconColor = colorMode === 'light' ? iconColorLight : iconColorDark;

    return (
        <Center>
            <Tooltip
                label={companyDesc}
                placement='top right'
                maxW='400px'
                mb='10px'
                _text={{
                    fontWeight: '600',
                    fontSize: '16px'
                }}
            >
                <Button
                    w={0}
                    h={0}
                    _light={{
                        bg: 'none',
                        _pressed: { bg: 'none' },
                        _hover: { bg: 'none' },
                        color: 'text.dark',
                    }}
                    _dark={{
                        bg: 'none',
                        _pressed: { bg: 'none' },
                        _hover: { bg: 'none' },
                        color: 'text.light',
                    }}
                    mt='14px'
                    disabled
                >
                    <FaInfoCircle
                        cursor='pointer'
                        color={iconColor}
                        size={24}
                    />
                </Button>
            </Tooltip>
        </Center>
    );
}

export {
    ResumeItem
};
