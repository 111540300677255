import React from 'react';
// import { useLocation } from 'react-router';
import {
    Text,
    Stack,
    Hidden,
} from 'native-base';
import { SubPageButton } from '../components/common/SubPageButton';

interface WorkExpButtonProps {
    route: string;
    text: string;
    disabled: boolean;
}

const EsmeWork = () => {

    // // scroll to top of page after routing
    // const routePath = useLocation();
    // const onTop = () => {
    //     window.scrollTo(0, 0);
    // };
    // useEffect(() => {
    //     onTop();
    // }, [ routePath ]);


    return(
        <>
            <Stack
                w={'100%'}
                py='40px'
                px={{base: '10%', lg: undefined}}
                space={5}
            >
                <Text
                    fontSize={{base: '16px', lg: '20px'}}
                    fontWeight={500}
                >
                    {'Recently, I have been working at Esme Learning Solutions on their Riff Analytics platform.'}
                </Text>
                <Text
                    fontSize={{base: '16px', lg: '20px'}}
                    fontWeight={500}
                >
                    {'Here are some of the projects I worked on:'}
                </Text>
            </Stack>

            {/* SECTIONS */}
            <Stack
                w='100%'
                space={{base: 10, lg: 20}}
                alignItems='center'
            >
                {/* VOLLEYS */}
                <Stack
                    direction={{ base: 'column', lg: 'row' }}
                    alignItems={{ base: 'center', lg: 'center' }}
                    justifyContent={{base: undefined, lg: 'center'}}
                    w='100%'
                >
                    <Hidden from='lg'>
                        <Stack
                            maxW='450px'
                            alignItems='center'
                            mb='12px'
                        >
                            <Text
                                fontSize='32px'
                                fontWeight='semibold'
                            >
                                {'Conversational Flow'}
                            </Text>
                            <Text
                                fontSize='20px'
                                fontWeight='light'
                                textAlign={'center'}
                                mb='8px'
                            >
                                {'A metric which shows how you interact with others.'}
                            </Text>
                            {workExpButton({
                                route: '/projects/conversationalFlow',
                                text: 'Read More',
                                disabled: false,
                            })}
                        </Stack>
                    </Hidden>
                    <Stack
                        w={{ base: '100%', lg: '50%' }}
                        maxWidth='475px'
                        borderRadius={10}
                        shadow={4}
                    >
                        <img
                            src={require('../assets/volleys.png')}
                            width={'100%'}
                        />
                    </Stack>
                    <Hidden till='lg'>
                        <Stack
                            w={'50%'}
                            ml='5%'
                            alignItems={'center'}
                        >
                            <Text
                                fontSize='32px'
                                fontWeight='semibold'
                            >
                                {'Conversational Flow'}
                            </Text>
                            <Text
                                fontSize='20px'
                                fontWeight='light'
                                textAlign={'center'}
                                mb='8px'
                            >
                                {'A metric which shows how you interact with others.'}
                            </Text>
                            {workExpButton({
                                route: '/projects/conversationalFlow',
                                text: 'Read More',
                                disabled: false,
                            })}
                        </Stack>
                    </Hidden>
                </Stack>
                {/* NARRATIVE */}
                <Stack
                    direction={{ base: 'column', lg: 'row' }}
                    alignItems={{ base: 'center', lg: 'center' }}
                    justifyContent={{base: undefined, lg: 'center'}}
                    w='100%'
                >
                    <Stack
                        w={{base: '100%', lg: '45%'}}
                        mr={{base: '0px', lg: '5%'}}
                        alignItems={{base: 'center', lg: 'center'}}
                        justifyContent={'flex-end'}
                        mb={{base: '12px', lg: undefined}}
                    >
                        <Text
                            fontSize='32px'
                            fontWeight='semibold'
                        >
                            {'Meeting Narrative'}
                        </Text>
                        <Text
                            fontSize='20px'
                            fontWeight='light'
                            textAlign={'center'}
                            mb='8px'
                        >
                            {'An integrated metric which summarizes your meeting.'}
                        </Text>
                        {workExpButton({
                            route: '/projects/narrative',
                            text: 'Read More',
                            disabled: false,
                        })}
                    </Stack>
                    <Stack
                        w={{base: '100%', lg: '50%'}}
                        justifyContent={'center'}
                        alignItems='center'
                        maxWidth='475px'
                        borderRadius={10}
                        shadow={4}
                    >
                        <img
                            src={require('../assets/narrative.png')}
                            width='100%'
                            height='100%'
                        />
                    </Stack>
                </Stack>
                {/* MOBILE OVERHAUL */}
                <Stack
                    direction={{ base: 'column', lg: 'row' }}
                    alignItems={{ base: 'center', lg: 'center' }}
                    justifyContent={{base: undefined, lg: 'center'}}
                    w='100%'
                >
                    <Hidden from='lg'>
                        <Stack
                            // maxW='450px'
                            alignItems='center'
                            mb='12px'
                        >
                            <Text
                                fontSize='32px'
                                fontWeight='semibold'
                                textAlign={'center'}
                            >
                                {'Mobile Overhaul'}
                            </Text>
                            <Text
                                fontSize='20px'
                                fontWeight='light'
                                textAlign={'center'}
                                mb='8px'
                            >
                                {'Singlehandely made the entire website mobile-friendly.'}
                            </Text>
                            {workExpButton({
                                route: '/projects/mobileConversion',
                                text: 'Read More',
                                disabled: false,
                            })}
                        </Stack>
                    </Hidden>
                    <Stack
                        w={{ base: '100%', lg: '50%' }}
                        maxWidth='475px'
                        justifyContent={'flex-end'}
                        alignItems='flex-start'
                    >
                        <img
                            src={require('../assets/mobileViews.png')}
                            width='100%'
                        />
                    </Stack>
                    <Hidden till='lg'>
                        <Stack
                            w={'50%'}
                            ml='5%'
                            alignItems={'center'}
                        >
                            <Text
                                fontSize='32px'
                                fontWeight='semibold'
                                textAlign={'center'}
                            >
                                {'Mobile Overhaul'}
                            </Text>
                            <Text
                                fontSize='20px'
                                fontWeight='light'
                                textAlign={'center'}
                                mb='8px'
                            >
                                {'Singlehandely made the entire website mobile-friendly.'}
                            </Text>
                            {workExpButton({
                                route: '/projects/mobileConversion',
                                text: 'Read More',
                                disabled: false,
                            })}
                        </Stack>
                    </Hidden>
                </Stack>
            </Stack>
        </>
    );
};

const workExpButton = (props: WorkExpButtonProps) => {
    return (
        <SubPageButton
            labelText={props.text}
            navPath={props.route}
            disabled={props.disabled}
        />
    );
};

export {
    EsmeWork,
};
