import * as am5 from '@amcharts/amcharts5';
import * as am5flow from '@amcharts/amcharts5/flow';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useBreakpointValue, useColorMode } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';


const RibbonlessChordDiagram = () => {
    let graphRoot: am5.Root;
    const listOfNames = [
        'Oliver',
        'Bethany',
        'Robert',
        'Sam',
        'Thea',
        'Howard',
        'Caitlin',
        'Ali'
    ];

    const { colorMode } = useColorMode();
    const whiteOrBlack = am5.color(colorMode === 'light' ? '#000000' : '#ffffff');

    const location = useLocation();

    const [ colorModeChanged, setColorModeChanged ] = useState(false);

    const chartHeight = useBreakpointValue({
        base: '450px',
        lg: '600px',
    });

    useEffect(() => {
        initGraph();

        // Dismount the graph
        return () => {
            if (graphRoot) {
                graphRoot.dispose();
                setColorModeChanged(false);
            }
        };
    }, [ location.pathname, colorMode ]);

    useEffect(() => {
        setColorModeChanged(true);
    }, [ colorMode ]);

    function initGraph() {
        const root = am5.Root.new('ribbonlessChordChartDiv');

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        // Create series
        const series = root.container.children.push(
            am5flow.ChordNonRibbon.new(root, {
                sourceIdField: 'from',
                targetIdField: 'to',
                valueField: 'value',
                radius: am5.percent(80),
                nodeWidth: 5,
                padAngle: 0.5,
            }),
        );

        series._updateChildren();
        series.nodes.get('colors')?.set('step', 2);

        // series.nodes.get('colors')?.set('colors', [ whiteOrBlack ]);
        series.nodes.rectangles.template.setAll({
            fillOpacity: 1, // make nodes invisible
        });

        series.nodes.template?.events.removeType('dragged');

        series.bullets.push(function (_root, _series, dataItem) {
            const bullet: am5.Bullet = am5.Bullet.new(root, {
                locationY: Math.random(),
                sprite: am5.Circle.new(root, {
                    radius: 5,
                    fill: dataItem.get('source').get('fill')
                })
            });

            bullet.animate({
                key: 'locationY',
                to: 1,
                from: 0,
                duration: Math.random() * 1000 + 2000,
                loops: Infinity,
                easing: am5.ease.yoyo(am5.ease.cubic)
            });

            return bullet;
        });

        series.nodes.labels.template.setAll({
            text: '[bold]{name}',
            radius: 20,
            fill: whiteOrBlack,
        });

        series.links.template.setAll({
            tooltipText: '',
            templateField: 'strokeSettings',
        });

        series.data.setAll(createSeriesData());

        if (!colorModeChanged){
            series.appear(4000, 2500);
        }

        graphRoot = root;
    }

    function createSeriesData() {
        const seriesData = listOfNames.map((name) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const dataPerName: any = [];
            for (let i = 0; i < listOfNames.length; i++) {
                if (listOfNames[i] === name) {
                    break;
                }
                const value = 10;
                dataPerName.push({
                    id: `${name}-${listOfNames[i]}`,
                    from: listOfNames[i],
                    to: name,
                    value: value,
                    strokeSettings: {
                        strokeWidth: 1,
                        fillOpacity: (1),
                    },
                });
            }
            return dataPerName;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).reduce((tot: any, arr: any) => tot.concat(arr), []);

        return seriesData;
    }

    return (
        <div
            id='ribbonlessChordChartDiv'
            style={{ width: '100%', height: chartHeight }}
        >
        </div>
    );

};

export {
    RibbonlessChordDiagram
};
